









































































import {Component, Ref, Vue} from 'vue-property-decorator';
import PersistentDialog from "@/components/global/dialog/PersistentDialog.vue";
import NoRobot from "@/components/global/NoRobotV2.vue";

@Component({
    components: {PersistentDialog, NoRobot},
})
export default class PasswordResetConfirmEmail extends Vue {
    @Ref()
    private recapture!:NoRobot;
    private hash: string = "";
    private loading: boolean = false;
    private success: boolean = false;
    private password: string = "";
    private passwordRepeat: string = "";
    private valid: boolean = true;
    private error: boolean = false;
    private passWordRules = {
        required: value => !!value || this.$tc('register.new.validation.passwordRequired'),
        min: v => (v && v.length >= 8) || this.$tc('register.new.validation.passwordMinLength'),
    };
    private showPassword: boolean = false;
    @Ref()
    private confirmDialog!: PersistentDialog;

    async mounted() {
        this.loading = true;
        const query = { ...this.$route.query };
        if (query.h) {
            const h = query.h;
            this.hash = h.toString();
            delete query.h;
            await this.$router.push(query);
        }

        if (!this.hash) {
            await this.$router.push('login');
        } else {
            this.loading = false;
        }

        this.valid = false;
    }

    private async validate() {
        //@ts-ignore
        if (this.$refs.form.validate()) {
            const result = await this.recapture.validate();
            if(result) {
                this.loading = true;
                await this.$webApi.resetPasswordConfirmEmail(this.hash, this.password).then((result) => {
                    this.success = result;
                    this.error = !result;
                }).finally(() => {
                    this.loading = false;
                })
            } else {
                await this.recapture.reset();
            }
        }
    }
}

